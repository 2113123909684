<template>
  <article>
    <!-- Staff Tools -->
    <section class="staff-tools">
      <div class="group mb-5 relative">
        <h2 class="h2 group-name d-flex align-center justify-space-between">
          Staff Tools
          <v-icon color="#000" class="collape-icon" @click="areToolsVisible = !areToolsVisible">
            {{ areToolsVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </h2>

        <div class="ripple-loading" v-if="loading">
          <div></div>
          <div></div>
        </div>
        <template v-if="areToolsVisible">
          <aside class="server">
            <div class="server-body">
              <div class="d-flex align-center justify-space-between">
                <h3 class="h3 d-flex align-center">App Banner</h3>
                <span class="last-checked-in">
                  Status:
                  <span class="banner-status" v-if="bannerStatus === 'active'">Live</span>
                  <span class="banner-status inactive" v-if="bannerStatus === 'in-active'">Not Live</span>
                </span>
              </div>
            </div>
          </aside>

          <aside class="server">
            <div class="server-body">
              <div class="d-flex align-center justify-space-between">
                <h3 class="h3 d-flex align-center pb-2">Banner Options</h3>
              </div>

              <div class="form-group">
                <label for="bannerTextTop">Line 1</label>
                <input type="text" id="bannerTextTop" v-model="bannerTextTop" class="text-field" />
              </div>

              <div class="form-group">
                <label for="bannerTextBottom">Line 2</label>
                <input type="text" id="bannerTextBottom" v-model="bannerTextBottom" class="text-field" />
              </div>

              <div class="form-group">
                <label>Banner Type</label>
                <v-select
                  :items="bannerTypes"
                  dense
                  item-text="label"
                  item-value="value"
                  hide-details
                  outlined
                  v-model="bannerType" />
              </div>

              <strong>Preview</strong>
              <div class="banner text-center" :class="bannerType">
                <p class="mb-0" v-if="isBannerEmpty">Start typing in the above fields for preview.</p>
                <p class="mb-1">{{ bannerTextTop }}</p>
                <p class="mb-0">{{ bannerTextBottom }}</p>
              </div>

              <div class="text-right mt-8 mb-5">
                <button class="btn btn-border lg mr-3" @click="updateBannerHandler('in-active')">Remove Banner</button>
                <button class="btn btn-border inverse lg" @click="updateBannerHandler('active')" :disabled="isAddBannerButtonDisabled">Set Live</button>
              </div>
            </div>
          </aside>
        </template>
      </div>
    </section>
    <!-- Staff Tools -->

    <!-- Servers -->
    <section class="server-information mt-5">
      <Group v-for="(group, key) in servers" :key="key" :servers="group" :groupName="key" />
    </section>
    <!-- Servers -->

  </article>
</template>

<script>
import Group from './Group.vue';
import _axios from '../../../axios';
import axios from 'axios';

export default {
  name: "Servers",

  data() {
    return {
      areToolsVisible: false,
      bannerStatus: "",
      bannerTextBottom: "",
      bannerTextTop: "",
      bannerType: "",
      bannerTypes: [
        { value: "alert", label: "Alert" },
        { value: "warning", label: "Warning" },
        { value: "notice", label: "Notice" },
      ],
      loading: false,
      servers: {},
    }
  },

  mounted() {
    this.fetchServerStatus();
    this.fetchBanner();
  },

  computed: {
    isBannerEmpty() {
      return this.bannerTextBottom === "" && this.bannerTextTop === "";
    },

    isAddBannerButtonDisabled() {
      const { bannerTextBottom, bannerTextTop, bannerType } = this;
      return bannerTextBottom === "" || bannerTextTop === "" || bannerType === "";
    }
  },

  components: {
    Group
  },

  methods: {
    async fetchBanner() {
      const response = await axios.get('/banners');
      const { banner_text_bottom, banner_text_top, banner_type, status } = response.data.data;
      this.bannerStatus = status;
      this.bannerTextBottom = banner_text_bottom;
      this.bannerTextTop = banner_text_top;
      this.bannerType = banner_type;
    },

    async fetchServerStatus() {
      const response = await _axios.get('sys/servers-status');
      this.servers = response.data.servers;
    },

    async updateBannerHandler(status) {
      this.loading = true;
      const payload = {
        banner_text_top: this.bannerTextTop,
        banner_text_bottom: this.bannerTextBottom,
        banner_type: this.bannerType,
        status: status
      }

      await axios.post('/update-banner', {
        ...payload
      });

      this.fetchBanner();
      this.loading = false;
    }
  }
}
</script>

<style lang="scss">
  .form-group {
    margin-top: 10px;
    margin-bottom: 20px;

    label {
      font-weight: bold;
      display: block;
    }

    .text-field {
      border-radius: 5px;
      border: 1px solid #ccc;
      height: 40px;
      margin-top: 4px;
      width: 100%;
      padding: 0 12px;
    }
  }

  .banner {
    background: #e2e3e5;
    color: #41464b;
    font-size: 16px;
    padding: 10px 16px;

    &.alert {
      background: #dc3545;
      color: #fff;
    }

    &.warning {
      background: #ffc107 !important;
      color: #000 !important;
    }

    &.notice {
      background: #35addc;
      color: #fff;
    }
  }

  .banner-status {
    background: #198754;
    border-radius: 20px;
    color: #fff;
    display: inline-block;
    font-weight: bold;
    padding: 5px 12px;
    text-transform: uppercase;
    margin-left: 5px;

    &.inactive {
      background: #dc3545;
    }
  }

  .relative {
    position: relative;
  }

  .btn-border {
    background: #fff !important;
    border-radius: 2px;
    border: 1px solid #0e3b4d;
    color: #0e3b4d;
    font-size: 12px !important;
    font-weight: bold;
    height: 30px !important;
    line-height: 24px;
    padding: 0 12px;

    &:hover:not(:disabled),
    &.selected {
      color: #fff;
      background: #0e3b4d !important;

      path {
        fill: #fff;
      }
    }

    &.inverse {
      background: #0e3b4d !important;
      color: #fff;
    }

    &.lg {
      border-radius: 4px;
      font-size: 16px !important;
      height: 45px !important;
      min-width: 202px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: .5;

      &.inverse {
        color: #fff !important;
      }
    }
  }
</style>
