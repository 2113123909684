<template>
  <div class="group mb-5" :class="{ 'd-none': groupName == '' }">
    <h2 class="h2 group-name d-flex align-center justify-space-between">
      {{ groupName }}
      <v-icon color="#000" class="collape-icon" @click="areServersVisible = !areServersVisible">
        {{ areServersVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
      </v-icon>
    </h2>
    <template v-if="areServersVisible">
      <Server v-for="server in activeServers" :server="server" :key="server.id" />
    </template>
  </div>
</template>

<script>
import Server from './Server.vue';

export default {
  name: 'Group',

  props: {
    groupName: {
      type: String,
      required: true
    },

    servers: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      areServersVisible: true
    }
  },

  computed: {
    activeServers() {
      return this.servers.filter(server => server);
    }
  },

  components: {
    Server
  }
}
</script>

<style lang="scss">
  .group {
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;

    .collape-icon {
      cursor: pointer;
    }
  }

  .group-name {
    background: rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    padding: .5rem 1rem;
    text-transform: capitalize;
  }
  
  .server {
    background: rgba(0, 0, 0, 0.06);
    font-weight: normal;
    padding: 1rem;

    & + .server {
      padding-top: 0;
    }

    &-body {
      background: #fff;
      padding: .5rem 1rem;
      border-radius: 6px;
    }
  }

  .last-checked-in {
    font-size: 12px;
  }

  .queue-name {
    text-transform: capitalize;
    white-space: nowrap;
  }

  .queue-list {
    list-style: none;
    margin: 16px -4px 4px -4px !important;
    padding: 0 !important;

    li {
      border-radius: 6px;
      color: #eee;
      display: inline-block;
      font-size: 13px;
      margin: 4px;
      padding: 4px 8px;

      &.error {
        background: #dc3545 !important;
      }

      &.warning {
        background: #ffc107 !important;
        color: #000;
      }

      &.success {
        background: #198754 !important;
      }
    }

    .jobs-count {
      display: inline-block;
      background: #fff;
      border-radius: 6px;
      color: #000;
      padding: 0 4px;
      letter-spacing: 1px;
    }
  }
</style>