<template>
  <aside class="server">
    <div class="server-body">
      <div class="d-flex align-center justify-space-between">
        <h3 class="h3 d-flex align-center">{{ server.label }} <small class="pl-1">({{ server.public_ip }})</small></h3>
        <span class="last-checked-in">
          last checked at <strong>{{ server.last_ping_at | humanReadableDateTime }}</strong>
        </span>
      </div>
      <ul class="queue-list">
        <li cols="auto" v-for="(queue, key) in this.server.queue" :key="key" class="queue-name" :class="queueIndicator(queue)">
          <strong>{{ key }} - <span class="jobs-count">{{ queue }}</span></strong>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'Server',

  props: {
    server: {
      type: Object,
      required: true
    }
  },

  methods: {
    queueIndicator(queue) {
      let className = '';
      if(queue <= 0) { className = 'd-none' }
      else if(queue > 0 && queue < 500) { className = 'success' }
      else if(queue >= 500 && queue < 1000) { className = 'warning' }
      else if(queue >= 1000) { className = 'error' }

      return className;
    }
  }
}
</script>
